import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";

import { Router } from "./Router";

type AppProps = {
	instance: PublicClientApplication;
};

export default function App({ instance }: AppProps) {
	return (
		<MsalProvider instance={instance}>
			<Router />
		</MsalProvider>
	);
}
