import { InteractionStatus } from "@azure/msal-browser";
import { Box, Button, Container, Typography } from "@mui/material";
import { useAuth } from "@rdit-dps/arcane-sso-react";
import { Navigate } from "react-router-dom";

import { Spinner } from "../../components/ui";

const MSLogoSvg = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20px"
			height="20px"
			viewBox="0 0 21 21"
		>
			<path fill="#f35325" d="M0 0h10v10H0z" />
			<path fill="#81bc06" d="M11 0h10v10H11z" />
			<path fill="#05a6f0" d="M0 11h10v10H0z" />
			<path fill="#ffba08" d="M11 11h10v10H11z" />
		</svg>
	);
};

export const LoginPage = () => {
	const { isAuthenticated, login, inProgress } = useAuth();
	if (
		inProgress === InteractionStatus.Startup ||
		inProgress === InteractionStatus.HandleRedirect
	) {
		return <Spinner fullscreen />;
	}
	if (isAuthenticated && inProgress === InteractionStatus.None)
		return <Navigate to="/dashboard" replace />;

	const handleLogin = () => {
		login();
	};

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				minHeight: "100vh",
			}}
		>
			<Container
				component="main"
				sx={{
					maxWidth: "700px",
					flexGrow: 1,
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center",
					textAlign: "center",
				}}
			>
				<Typography variant="h1" mb={8}>
					Sign-in to AstraZeneca Platform Tools
				</Typography>
				<Button
					color="primary"
					size="large"
					variant="outlined"
					onClick={handleLogin}
					sx={{
						borderRadius: "36px",
						padding: "16px 24px",
					}}
				>
					<MSLogoSvg />
					<Typography ml={2}>Sign in with Microsoft</Typography>
				</Button>
			</Container>
			<Box
				sx={{
					textAlign: "center",
					paddingBottom: "16px", // Add some padding to give space for the text
				}}
			>
				<Typography color="textSecondary" variant="subtitle2">
					© AstraZeneca 2023. All rights reserved.
				</Typography>
			</Box>
		</Box>
	);
};
