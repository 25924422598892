import Box from "@mui/material/Box";
import { AppRoutes } from "@rdit-dps/arcane-ui-tool";

import { NavItem } from "../layout";

export const DashboardPage = () => {
	const applicationConfigString =
		window.localStorage.getItem("applications") ?? "";

	const appRoutes = AppRoutes(applicationConfigString, false);

	return (
		<Box sx={{ flexGrow: 1, display: "flex", m: 5 }}>
			{appRoutes.map(({ path, title, icon }, index) => (
				<Box
					key={title}
					sx={{ marginRight: index < appRoutes.length - 1 ? 2 : 0 }}
				>
					<NavItem key={title} path={path} title={title} icon={icon} />
				</Box>
			))}
		</Box>
	);
};
