import { DashboardPage, LoginPage } from "../pages";

export const navRoutes = [
	{
		path: "/dashboard",
		element: <DashboardPage />,
		title: "Dashboard",
	},
];

export const publicRoutes = [
	{ path: "/", element: <LoginPage />, title: "Login" },
];

export const privateRoutes = [...navRoutes];
