import { Box, Button, Typography, useTheme } from "@mui/material";
import { useLocation } from "react-router-dom";
import * as singleSpa from "single-spa";

type Props = {
	path: string;
	title: string;
	icon: React.ReactElement;
};

export const NavItem = (props: Props) => {
	const { path, title, icon } = props;
	const { palette } = useTheme();
	const location = useLocation();
	const active = path ? location.pathname.startsWith(path) : false;

	return (
		<Button
			sx={{
				borderRadius: 10,
				color: "primary.dark",
				backgroundColor: "common.white",
				justifyContent: "flex-start",
				px: 3,
				textAlign: "center",
				textTransform: "none",
				width: "200px",
				height: "200px",
				border: "1px solid",
				"&:hover": {
					backgroundColor: palette.primary.light,
				},
				...(active && { fontWeight: "fontWeightBold" }),
				...(active && { border: `1px solid ${palette.primary.dark}` }),
			}}
			onClick={() => singleSpa.navigateToUrl(path)}
		>
			<Box sx={{ flexGrow: 1 }}>
				{icon}
				<Typography variant="h6">{title}</Typography>
			</Box>
		</Button>
	);
};
