export const paletteStyles = {
	common: {
		black: "#000000",
		white: "#FFFFFF",
	},
	background: {
		default: "#F7F7F7",
		paper: "#F7F7F7",
	},
	divider: "#E6E8F0",
	primary: {
		main: "#D0006F",
		light: "#FDF2F8",
		dark: "#830051",
		contrastText: "#000000",
	},
	secondary: {
		main: "#696969",
		light: "#8f8d8d",
		dark: "#403f3f",
		contrastText: "#FFFFFF",
	},
	info: {
		main: "#0B79D0",
		light: "#64B6F7",
		dark: "#054e87",
		contrastText: "#FFFFFF",
	},
	success: {
		main: "#2E7D32",
		light: "#4CAF50",
		dark: "#1B5E20",
		contrastText: "#FFFFFF",
	},
	warning: {
		main: "#FFB020",
		light: "#FFBF4C",
		dark: "#B27B16",
		contrastText: "#FFFFFF",
	},
	error: {
		main: "#D14343",
		light: "#DA6868",
		dark: "#922E2E",
		contrastText: "#FFFFFF",
	},
	text: {
		primary: "#121828",
		secondary: "#65748B",
		disabled: "rgba(55, 65, 81, 0.48)",
	},
	positive: "#00b400",
	negative: "#ff0000",
};
