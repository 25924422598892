import { createTheme, responsiveFontSizes } from "@mui/material";
import { deepmerge } from "@mui/utils";

import { paletteStyles } from "./palette";
import { typographyStyles } from "./typography";

const initTheme = createTheme({
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 960,
			lg: 1280,
			xl: 1920,
		},
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					textTransform: "none", // Disable text transformation
				},
			},
		},
		MuiTableHead: {
			styleOverrides: {
				root: {
					backgroundColor: "#E3E3E3",
				},
			},
		},
	},
});

const theme = createTheme({
	palette: paletteStyles,
	typography: typographyStyles(),
});

export default responsiveFontSizes(deepmerge(initTheme, theme));
