import { styled } from "@mui/material/styles";
import { FieldError } from "react-hook-form";

const StyledGridOverlay = styled("div")(() => ({
	color: "#f44336",
	margin: "0",
	fontSize: "0.75rem",
	marginTop: "8px",
	minHeight: "1em",
	textAlign: "left",
	fontWeight: "700",
	letterSpacing: "0.03333em",
}));

interface ErrorMessageProps {
	error: FieldError;
}

export const ErrorMessage = (props: ErrorMessageProps) => {
	return <StyledGridOverlay>{props.error.message}</StyledGridOverlay>;
};
