import { InteractionStatus, InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { loginRequest, useAuth } from "@rdit-dps/arcane-sso-react";
import { Layout } from "@rdit-dps/arcane-ui-tool";
import React from "react";
import { Outlet, Route, Routes } from "react-router-dom";

import { Spinner } from "../components/ui";
import { privateRoutes, publicRoutes } from "./routes";

const LoadingSpinner = () => <Spinner fullscreen />;

const PrivateRoutes = () => {
	const { instance, inProgress } = useMsal();
	const { logout } = useAuth();

	const activeAccount = instance.getActiveAccount();

	const authRequest = {
		...loginRequest,
		...(activeAccount && { account: activeAccount }),
		redirectUri: process.env.REACT_APP_REDIRECT_URL,
	};

	const handleLogout = (e: React.MouseEvent) => {
		e.preventDefault();
		logout();
	};

	if (inProgress === InteractionStatus.Startup) {
		return <Spinner fullscreen />;
	}

	return (
		<MsalAuthenticationTemplate
			interactionType={InteractionType.Redirect}
			authenticationRequest={authRequest}
			loadingComponent={LoadingSpinner}
		>
			<Layout
				handleLogout={handleLogout}
				navRoutes={[]}
				appName={"Dashboard"}
				isSidebar={false}
				toolName={"Platform Tools"}
				appRoute={[]}
			>
				<Outlet />
			</Layout>
		</MsalAuthenticationTemplate>
	);
};

export const Router = () => (
	<Routes>
		{publicRoutes.map((r) => (
			<Route key={r.path} path={r.path} element={r.element} />
		))}

		<Route element={<PrivateRoutes />}>
			{privateRoutes.map((r) => (
				<Route key={r.path} path={r.path} element={r.element} />
			))}
		</Route>
	</Routes>
);
