import { Box, CircularProgress } from "@mui/material";

type Props = {
	fullscreen?: boolean;
	opacity?: number;
	size?: number;
	color?: string;
};

const fullscreenWrapper = (children: React.ReactElement, opacity: number) => (
	<Box
		position="absolute"
		top={0}
		left={0}
		bottom={0}
		right={0}
		display="flex"
		alignItems="center"
		justifyContent="center"
		bgcolor={`rgba(255, 255, 255, ${opacity})`}
	>
		{children}
	</Box>
);

export const Spinner = ({
	fullscreen = false,
	size = 50,
	color = "primary",
	opacity = 1,
}: Props) => {
	const spinnerElement = <CircularProgress size={size} sx={{ color }} />;
	if (fullscreen) {
		return fullscreenWrapper(spinnerElement, opacity);
	}
	return spinnerElement;
};
