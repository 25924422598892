const commonHeaderStyles = {
	fontFamily: "'LexiaDaMa', sans-serif",
	lineHeight: 1.375,
};

export const typographyStyles = () => ({
	fontFamily: "'Roboto', sans-serif",
	fontWeight: 300,
	letterSpacing: "0.5px",
	button: {
		fontWeight: 600,
	},
	body1: {
		fontSize: "1rem",
		fontWeight: 400,
		lineHeight: 1,
	},
	body2: {
		fontSize: "0.875rem",
		fontWeight: 400,
		lineHeight: 1.57,
	},
	subtitle1: {
		fontSize: "1rem",
		fontWeight: 500,
		lineHeight: 1.75,
	},
	subtitle2: {
		fontSize: "0.875rem",
		fontWeight: 500,
		lineHeight: 1.57,
	},
	h1: {
		...commonHeaderStyles,
		fontSize: "3.5rem",
	},
	h2: {
		...commonHeaderStyles,
		fontSize: "3rem",
	},
	h3: {
		...commonHeaderStyles,
		fontSize: "2.25rem",
	},
	h4: {
		...commonHeaderStyles,
		fontSize: "2rem",
	},
	h5: {
		...commonHeaderStyles,
		fontSize: "1.4rem",
	},
	h6: {
		...commonHeaderStyles,
		fontSize: "1.125rem",
	},
});
